function delay(timeoutMs) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeoutMs);
  });
}

export default async function downloadFile(url, fileName) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  a.setAttribute('target', '_blank');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  await delay(1500);
}
